import React from 'react';
import ReactDOM from 'react-dom';
import Widget from './Widget';
import FirebaseService from './services/FirebaseService';
import FirebaseContext from './context/FirebaseContext';

/*
 { ...window.xprops } in Widget is
 used to receive props from zoid iframes:
 https://www.npmjs.com/package/zoid
 https://github.com/krakenjs/zoid

 zoid.frame.min.js and widget definition
 is loaded below
 (served by kitana-comments-server)
*/
const tag = document.createElement('script');
tag.src = `${process.env.REACT_APP_SERVER}/widget`;
tag.addEventListener('load', () => {
  ReactDOM.render(
    <React.StrictMode>
      <FirebaseContext.Provider value={new FirebaseService()}>
        <Widget {...window.xprops} />
      </FirebaseContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
document.body.appendChild(tag);
