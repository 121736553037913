import React, { useState, useContext } from 'react';
import FirebaseContext from './../context/FirebaseContext';
import RatingSelector from './RatingSelector';

const CommentsForm = ({ postComment }) => {
  const firebase = useContext(FirebaseContext);
  const user = firebase.auth.currentUser;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [rating, setRating] = useState(0);

  const onSubmit = event => {
    event.preventDefault();
    postComment({ name, email, rating, message }).then(() => {
      setName('');
      setEmail('');
      setMessage('');
      setRating(0);
    });
  };

  return (
    <form className="form" onSubmit={onSubmit}>
      <div className="row">
        {!user.displayName ? (
          <input
            type="text"
            placeholder="Tu nombre"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        ) : null}
        {!user.email ? (
          <input
            type="email"
            placeholder="Tu e-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        ) : null}
      </div>
      <RatingSelector rating={rating} setRating={setRating}></RatingSelector>
      <textarea
        rows="6"
        placeholder="Escribe aquí tu comentario"
        value={message}
        onChange={e => setMessage(e.target.value)}
        required
      ></textarea>
      <button type="submit">Enviar</button>
    </form>
  );
};

export default CommentsForm;
