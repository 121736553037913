import axios from 'axios';

const API = {
  get: endpoint => {
    return axios.get(`${process.env.REACT_APP_SERVER}${endpoint}`).then(response => response.data);
  },

  post: (endpoint, data, token = null) => {
    return axios
      .post(`${process.env.REACT_APP_SERVER}${endpoint}`, data, {
        headers: {
          Token: token,
        },
      })
      .then(response => response.data);
  },
};

export default API;
