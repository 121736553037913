import React, { useContext, useState, useEffect } from 'react';
import FirebaseContext from './../context/FirebaseContext';

const UserProfile = () => {
  const firebase = useContext(FirebaseContext);
  const [name, setName] = useState(null);

  useEffect(() => {
    if (firebase.auth.currentUser.displayName) {
      setName(firebase.auth.currentUser.displayName);
    } else {
      setName(firebase.auth.currentUser.email);
    }
  }, [firebase.auth.currentUser]);

  const logout = () => {
    firebase.auth.signOut();
  };

  return (
    <header className="user-profile">
      <p>Hola {name}!</p>
      <button type="button" onClick={logout}>
        Salir
      </button>
    </header>
  );
};

export default UserProfile;
