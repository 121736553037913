import React, { useState, useEffect, useContext } from 'react';
import API from './services/API';
import FirebaseContext from './context/FirebaseContext';
import UserProfile from './components/UserProfile';
import LoginForm from './components/LoginForm';
import CommentsForm from './components/CommentsForm';
import CommentsListing from './components/CommentsListing';
import Alert from './components/Alert';
import './css/global.scss';
import AlertMessage from './classes/AlertMessage';

function Widget({ channelId, url }) {
  const firebase = useContext(FirebaseContext);

  const [user, setUser] = useState(null);
  const [comments, setComments] = useState([]);
  const [alert, setAlert] = useState(null);
  const [page, setPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(user => setUser(user));
  }, [firebase.auth]);

  useEffect(() => {
    API.get(`/messages?channelId=${channelId}&url=${url}&size=${pageSize}`).then(data => {
      setComments(data.messages);
      setCanLoadMore(data.total > pageSize);
    });
  }, [url, channelId, pageSize]);

  const loadMore = () => {
    API.get(`/messages?channelId=${channelId}&url=${url}&size=${pageSize}&page=${page + 1}`).then(
      data => {
        setComments([...comments, ...data.messages]);
        setCanLoadMore(data.total > (page + 1) * pageSize);
        setPage(page => page + 1);
      }
    );
  };

  const postComment = async comment => {
    const token = await firebase.token();
    return API.post('/messages', { ...comment, url, channelId }, token)
      .then(newComment => {
        if (newComment.approved) {
          setComments(comments => [newComment, ...comments]);
        } else {
          setAlert(new AlertMessage('Tu comentario será revisado por un administrador.', 'alert'));
        }
      })
      .catch(() => {
        setAlert(new AlertMessage('Error al enviar tu comentario.'));
      });
  };

  return (
    <div className="App">
      <Alert content={alert} onClose={() => setAlert(null)}></Alert>
      {user ? (
        <>
          <UserProfile></UserProfile>
          <CommentsForm postComment={postComment}></CommentsForm>
        </>
      ) : (
        <LoginForm setAlert={setAlert}></LoginForm>
      )}
      <CommentsListing comments={comments}></CommentsListing>
      {canLoadMore ? (
        <button type="button" onClick={loadMore}>
          Ver más comentarios...
        </button>
      ) : null}
    </div>
  );
}

export default Widget;
