import React, { useContext } from 'react';
import FirebaseContext from './../context/FirebaseContext';

const LoginForm = ({ setAlert }) => {
  const firebase = useContext(FirebaseContext);
  /*const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  import AlertMessage from './../classes/AlertMessage';
  const login = () => {
    firebase
      .signInWithEmailAndPassword(email, password)
      .catch(err => setAlert(new AlertMessage(err.message)));
  };

  const signUp = () => {
    firebase
      .createUserWithEmailAndPassword(email, password)
      .then(console.log)
      .catch(err => setAlert(new AlertMessage(err.message)));
  };
  */

  const appleSignup = () => {
    firebase.signInWithApple();
  };

  const googleSignUp = () => {
    firebase.signInWithGoogle();
  };

  const facebookSignUp = () => {
    firebase.signInWithFacebook();
  };

  return (
    <form className="form">
      {/*<div className="row">
        <input
          type="email"
          placeholder="Tu e-mail"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Tu contraseña"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
        />
      </div>
      <button type="button" onClick={login}>
        Entrar
      </button>
      <button type="button" onClick={signUp}>
        Registrarme
      </button>*/}
      <button type="button" onClick={appleSignup} className="apple">
        Entrar con Apple
      </button>
      <button type="button" onClick={googleSignUp} className="google">
        Entrar con Google
      </button>
      <button type="button" onClick={facebookSignUp} className="facebook">
        Entrar con Facebook
      </button>
    </form>
  );
};

export default LoginForm;
