import React, { useState, useEffect } from 'react';

const RatingSelector = ({ rating, setRating, enabled = true }) => {
  const [hovering, setHovering] = useState(0);
  const ratings = [1, 2, 3, 4, 5];

  useEffect(() => {
    setHovering(0);
  }, [rating]);

  const onSelect = value => (enabled ? setRating(value) : null);
  const onMouseOver = value => (enabled ? setHovering(value) : null);
  const onMouseOut = rating => (enabled ? setHovering(rating) : null);

  return (
    <div className={`rating ${enabled ? 'enabled' : ''}`}>
      {ratings.map(value => (
        <Star
          key={value}
          active={rating >= value || hovering >= value}
          onSelect={() => onSelect(value)}
          onMouseOver={() => onMouseOver(value)}
          onMouseOut={() => onMouseOut(rating)}
        ></Star>
      ))}
    </div>
  );
};

const Star = ({ active, onSelect, onMouseOver, onMouseOut }) => {
  return (
    <span
      className={`star ${active ? 'active' : ''}`}
      onClick={onSelect}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    ></span>
  );
};

export default RatingSelector;
