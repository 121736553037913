import React from 'react';
import { formatRelative } from 'date-fns';
import { es } from 'date-fns/locale';
import RatingSelector from './RatingSelector';

const Comment = ({ comment }) => {
  return (
    <div className="comment">
      <header>
        <div className="user">
          <p className="name">{comment.name}</p>
          {comment.rating ? (
            <RatingSelector rating={comment.rating} enabled={false}></RatingSelector>
          ) : null}
        </div>
        <p className="date">
          {formatRelative(new Date(comment.createdAt), new Date(), { locale: es })}
        </p>
      </header>
      <p className="comments">{comment.message}</p>
    </div>
  );
};

export default Comment;
