import React from 'react';

const Alert = ({ content, onClose }) => {
  if (!content) {
    return null;
  }

  return (
    <div className="alert-container">
      <div className={`alert ${content.type}`}>
        <span className="close" onClick={onClose}></span>
        <p>{content.text}</p>
      </div>
    </div>
  );
};

export default Alert;
