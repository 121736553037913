import React from 'react';
import Comment from './Comment';

const CommentsListing = ({ comments }) => {
  if (!comments.length) {
    return (
      <p className="empty">
        Se el primero en <strong>dejar un comentario</strong>.
      </p>
    );
  }

  return (
    <div className="comments-listing">
      {comments.map(comment => (
        <Comment key={comment.id} comment={comment}></Comment>
      ))}
    </div>
  );
};

export default CommentsListing;
