import app from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
  }

  createUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signInWithApple = () => {
    const provider = new app.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    this.auth.signInWithPopup(provider);
  };

  signInWithGoogle = () => {
    const provider = new app.auth.GoogleAuthProvider();
    this.auth.signInWithPopup(provider);
  };

  signInWithFacebook = () => {
    const provider = new app.auth.FacebookAuthProvider();
    this.auth.signInWithPopup(provider);
  };

  signOut = () => this.auth.signOut();

  passwordReset = email => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = password => this.auth.currentUser.updatePassword(password);

  token = () => (this.auth.currentUser ? this.auth.currentUser.getIdToken(true) : null);
}

export default Firebase;
