class AlertMessage {
  constructor(text, type = 'error') {
    this._type = type;
    this._text = text;
  }

  get text() {
    return this._text;
  }

  get type() {
    return this._type;
  }
}

export default AlertMessage;
